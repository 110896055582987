import React, { FC } from "react";
import { OfferTemplate } from "@templates/OfferTemplate";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import styled from "styled-components";
import { breakpoints } from "@theme/breakpoints";
import { UniversalSection } from "@components/molecules/UniversalSection";
import { ChildImageSharp } from "@interfaces/childImageSharp";
import { SectionHeadline } from "@components/atoms/SectionHeadline";
import HtmlParser from "react-html-parser";
import { stylesWrapper } from "@theme/commonStyles";
import { CreationProcesses } from "@components/atoms/CreationProcesses";
import { ImageTextItem } from "@components/atoms/ImageTextItem";
import { ReactComponent as World } from "@vectors/worldwide.svg";
import WhatWeCanDoMore from "@components/organisms/WhatWeCanDoMore";

const StyledSectionHeadline = styled(SectionHeadline)`
  ${stylesWrapper};
  margin-top: 140px;
  margin-bottom: 0;

  ${breakpoints.tabletMax`
    margin-top: 100px;
  `}

  ${breakpoints.phoneMax`
    margin-top: 80px;
  `}
`;

const StyledItemsWrapper = styled.section`
  ${stylesWrapper};
  margin-top: 140px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: -90px;

  ${breakpoints.tabletMax`
    flex-direction: column;
    margin-top: 100px;
    margin-bottom: -60px;
  `}

  ${breakpoints.phoneMax`
    margin-top: 80px;
    margin-bottom: -40px;
  `}
`;

const StyledItem = styled(ImageTextItem)`
  margin-bottom: 90px;
  width: 48%;
  max-width: 590px;

  ${breakpoints.tabletMax`
    width: 100%;
    max-width: unset;
    margin-bottom: 60px;
  `}

  ${breakpoints.phoneMax`
    margin-bottom: 40px;
  `}
`;

const CreationProcessesWrapper = styled.div`
  ${stylesWrapper};
  margin-top: 100px;

  ${breakpoints.tabletMax`
    margin-top: 60px;
  `}

  ${breakpoints.phoneMax`
    margin-top: 40px;
  `}
`;

const DeveloperWebsite: FC<Props> = ({ data }) => {
  const { t } = useTranslation("offer");
  const { t: ct } = useTranslation("common");

  const vectorsAndImages = [
    "/calendar.png",
    "/real-estate.png",
    World,
    "/ui-design.png",
  ];

  return (
    <OfferTemplate
      title={ct("title-offer-developer-website")}
      description={ct("description-developer-website")}
      heroTitle={t("developer-website.hero.title")}
      heroDescription={t("developer-website.hero.description", {
        returnObjects: true,
      })}
      heroImage={data.heroImage.childImageSharp.gatsbyImageData}
    >
      <UniversalSection
        description={t("developer-website.sections.0.description", {
          returnObjects: true,
        })}
        images={[data.firstSectionImage.childImageSharp.gatsbyImageData]}
        fromRight
      />

      <StyledItemsWrapper>
        {vectorsAndImages.map((vector, index) => (
          <StyledItem
            key={index}
            vector={vector}
            name={t(`developer-website.sections.1.items.${index}.title`)}
            description={t(
              `developer-website.sections.1.items.${index}.description`
            )}
          />
        ))}
      </StyledItemsWrapper>

      <StyledSectionHeadline>
        {HtmlParser(t("developer-website.sections.how-we-work"))}
      </StyledSectionHeadline>

      <CreationProcessesWrapper>
        <CreationProcesses withoutNavigation group={0} />
      </CreationProcessesWrapper>

      <WhatWeCanDoMore exclude={2} />
    </OfferTemplate>
  );
};

interface Props {
  data: {
    heroImage: ChildImageSharp;
    firstSectionImage: ChildImageSharp;
  };
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    heroImage: file(name: { eq: "offer_developer-website_1" }) {
      childImageSharp {
        gatsbyImageData(quality: 80)
      }
    }

    firstSectionImage: file(name: { eq: "offer_developer-website" }) {
      childImageSharp {
        gatsbyImageData(quality: 80)
      }
    }
  }
`;

export default DeveloperWebsite;
